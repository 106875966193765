<template>
  <div
    @click="handleRedirect"
    :class="['w-full bg-white shadow-lg rounded-lg relative my-4', { 'border-red-600': isYoutube}]"
  >
    <img class="w-full h-60 rounded-t-lg object-fill object-center"
         :src="article.imageUrl"
         alt="kebaktian">
    <img v-if="isYoutube" class="w-9 h-9 absolute right-4 bottom-4" src="~@/assets/icons/youtube.svg" alt="youtube">
    <div class="flex flex-col p-4 w-full">
      <div class="text-sm text-gray-400 text-right">{{ friendlyDate }}</div>
      <div class="text-lg font-bold text-gray-800 w-5/6">{{ article.title }}</div>
      <div class="text-base text-gray-400 w-5/6">{{ article.summary }}</div>
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/utils/time'
import { commonConfig } from '@/config'

export default {
  name: 'ArticleCard',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  computed: {
    friendlyDate () {
      return dateFormater(this.article.createdDate)
    },
    isYoutube () {
      return this.article.type === commonConfig.typeYoutube
    }
  },
  methods: {
    handleRedirect () {
      return this.$router.push({
        name: 'DetailArticle',
        params: {
          id: this.article.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
